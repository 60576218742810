"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const blockstack_1 = require("blockstack");
const globalAppConfig = new blockstack_1.AppConfig();
const globalUserSession = new blockstack_1.UserSession({ appConfig: globalAppConfig });
class UserSessionService {
    static get appConfig() {
        return globalAppConfig;
    }
    static get userSession() {
        return globalUserSession;
    }
    static signInWithSessionData(sessionInfo) {
        window.localStorage.setItem("blockstack-session", JSON.stringify(sessionInfo));
        return UserSessionService.userSession.handlePendingSignIn(sessionInfo.userData.authResponseToken);
    }
    static async getJsonAsync(path) {
        try {
            const json = (await UserSessionService.userSession.getFile(path));
            return JSON.parse(json);
        }
        catch (e) {
            console.error(e);
        }
        return undefined;
    }
    static async putJsonAsync(path, obj) {
        try {
            const json = JSON.stringify(obj);
            await UserSessionService.userSession.putFile(path, json);
            return true;
        }
        catch (e) {
            console.error(e);
        }
        return false;
    }
}
exports.UserSessionService = UserSessionService;
